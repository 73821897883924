.new-entity{
    width: 600px;
}

.new-connection-buttons-list{
    text-align: end;
    //width: 50%;
}

.header-buttons {
    margin-left: 10px;
    border-radius: 20px;
  //  background-color: #288560;
    border-color: #45CCB1;
    height: 25px;
    font-size: 13px;
}
.page-buttons{
    margin-left: 10px;
    border-radius: 20px;
    background-color: #288560;
    border-color: #45CCB1;
    height: 25px;
    font-size: 12px;
}

.seperator-cls{
    margin-top: 15px;
}
.dropdown-height {
    height: 150px;
    overflow-y: auto;
}
.temp-cls{
    max-height: 200px;
}