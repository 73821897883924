.new-domain{
    width: 900px;
}
.center-align{
    text-align: center;
}
.new-domain-buttons-list{
    text-align: end;
    //width: 50%;
}
.header-buttons {
    margin-left: 10px;
    border-radius: 20px;
   // background-color: #288560;
    border-color: #45CCB1;
    height: 25px;
    font-size: 13px;
}
.page-buttons{
    margin-left: 10px;
    border-radius: 20px;
    background-color: #288560;
    border-color: #45CCB1;
    height: 25px;
    font-size: 12px;
}
.seperator-cls{
    margin-top: 15px;
}
.single-cls{
    width: 30%;
    margin-right: 10px;
}
.combine-cls{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.entity-dropdown{
    width: 90%;
}
.new-entity-button{
    height: 30px;
    font-size: 12px;
    margin-left: 10px;
}
.add-level-button{
    margin-top: 10px;
    height: 25px;
    font-size: 12px;
}
.add-row-button{
    margin-top: 5px;
    height: 25px;
    font-size: 12px;
}
.dropdown-height {
    height: 150px;
    overflow-y: auto;
}
.submit-level-button{
    margin-top: 7px;
    border-radius: 20px;
    height: 25px;
    font-size: 12px;
}

