$standard-padding: 8px;
$image-dimension: 32px;
$nav-image-container-width: $image-dimension + 2 * $standard-padding;

.mint-systemconfig-container,
.mint-systemconfig-header-wrapper {
  display: grid;
  overflow: hidden;
}

.horizontal-nav-bar-placeholder {
  margin-left: $nav-image-container-width;
  overflow: hidden;
  padding: 0 8px;
}

.component-placeholder {
  margin-left: $nav-image-container-width;
  padding: 0 $standard-padding $standard-padding $standard-padding;
  overflow: hidden;
}

.component-scroll-config {
  height: 90vh;
  position: relative;
}
