@import "./side-nav-bar-style-variables.scss";

//******************SideNavPanel**********************************************

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.side-nav-panel {
  height: 100%;
  // z-index: 99;
  overflow-y: auto;
}

nav.main-menu {
  width: $sideNavsize-collapse;
  overflow-y: auto;
  overflow-x: hidden;
}

.main-menu-expand {
  width: $sideNavsize-expand !important;
}

.main-menu-collapse {
  width: $sideNavsize-collapse;
}

.main-menu {
  background: $sideNavColor;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: $sideNavsize-expand;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 100;
  opacity: $nav-opacity;
}

// for all the items
.main-menu li > .nav-item {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100%;
}
