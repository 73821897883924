.new-perspective{
    width: 1000px;
}
.center-align{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.new-perspective-buttons-list{
    text-align: end;
    
    //width: 50%;
}
.header-buttons {
    margin-left: 10px;
    border-radius: 20px;
    background-color: #288560;
    border-color: #45CCB1;
    height: 25px;
    font-size: 13px;
}
.page-buttons{
    margin-left: 10px;
    border-radius: 20px;
    background-color: #288560;
    border-color: #45CCB1;
    height: 25px;
    font-size: 12px;
}
.seperator-cls{
    margin-top: 15px;
}
.doubleseperator-cls{
    text-align: center;
    margin-top: 20px;
}
.single-cls{
    width: 45%;
}
.combine-cls{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.dropdown-height {
    height: 150px;
    overflow-y: auto;
}
.add-row-button{
    margin-top: 5px;
    height: 20px;
    font-size: 10px;
}




